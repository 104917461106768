<template>
	<div v-loading="pageloading">
		<div class="flexCol2">
			<div class="flexRow2 flexbetween">
				<div style="width:49.5%;">
					<el-card v-loading="toploading" style="height:100%" body-style="height:100%">
						<div class="flexCol2" style="height:100%">
							<div class="titfont">推荐概况</div>
							<div class="flexRow flexbetween " style="margin:15px 0px;flex:1">
								<div class="flexCol">
									<div class="numfont">{{topmsg.RecommendMallCount}}</div>
									<div class="textfont">
										<span>推荐商家数量</span>
										<el-button type="text" style="margin-left:10px;padding:0px"
											@click="lookmerchants">查看</el-button>
									</div>
								</div>
								<div class="flexCol">
									<div class="numfont">{{topmsg.ChannelBusinessMallCount}}</div>
									<div class="textfont">
										<span>渠道商家数量</span>
										<el-button type="text" style="margin-left:10px;padding:0px"
											@click="lookchannel">查看</el-button>
									</div>
								</div>
								<div class="flexCol">
									<div class="numfont">{{topmsg.AwardMoney}}</div>
									<div class="textfont">
										<span>累计奖励</span>
										<!-- <el-button type="text" style="margin-left:10px;padding:0px">查看</el-button> -->
									</div>
								</div>
								<div class="flexCol">
									<div class="numfont">{{topmsg.WaitDrawBalance}}</div>
									<div class="textfont">
										<span>可提现奖励</span>
										<el-button type="text" style="margin-left:10px;padding:0px" @click="withdrawal">
											提现</el-button>
									</div>
								</div>
							</div>
						</div>
					</el-card>
				</div>
				<el-card style="width:49.5%;height:100%">
					<div class="titfont">邀请商户</div>
					<div class="grayfont" style="margin:15px 0px">点击复制下方邀请链接或下载邀请海报，邀请好友使用企店，好友后续付费购买功能，您可获得奖励</div>
					<div class="flexRow" style="align-items:flex-start;">
						<div style="width:71px" ref="vueqr" id="vueqr">
							<img :src="posterImage" id="img-1" style="width:100%" alt="">
							<vue-qr v-if="Inputlink" :text="Inputlink" :callback="(url)=>{qrcallback(url,poster)}"
								:size="300" style="display:none" ref="Qrcode"></vue-qr>
						</div>
						<div style="flex:1;margin-left:15px">
							<div class="flexRow">
								<el-input v-model="Inputlink" disabled style="width:250px"></el-input>
								<el-button type="primary" v-clipboard:copy="Inputlink" v-clipboard:success="copysuc"
									v-clipboard:error="copyerr">复制邀请链接</el-button>
							</div>
							<el-button type="text" @click="btndownloadposter">下载邀请海报</el-button>
						</div>
					</div>
				</el-card>
			</div>
		</div>
		<el-card style="margin-top:10px">
			<div class="titfont">奖励明细</div>
			<div style="margin-top:20px">
				<el-table :data="tablelist" v-loading="tableloading" class="table" :header-row-style="{'height':'48px'}">
					<el-table-column prop="ChangeWaitDrawBalance" label="奖励">
						<template slot-scope="scope">
							￥{{scope.row.ChangeWaitDrawBalance}}
						</template>
					</el-table-column>
					<el-table-column prop="MallCommissionRecordTypeValue" label="奖励类型"></el-table-column>
					<el-table-column prop="AddTime" label="奖励时间"></el-table-column>
					<el-table-column prop="MallName" label="付费商家"></el-table-column>
					<el-table-column prop="PayMoney" label="支付金额">
						<template slot-scope="scope">
							￥{{scope.row.PayMoney}}
						</template>
					</el-table-column>
					<el-table-column prop="RuleName" label="付费服务"></el-table-column>
					<el-table-column prop="RecommendMallName" label="商家推荐人">
						<template slot-scope="scope">
							{{scope.row.RecommendMallName?scope.row.RecommendMallName:'--'}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="margin-top:10px;text-align:right" v-if="total">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</el-card>

		<el-dialog :visible.sync="merchantsshow" title="推荐商家" @close="closemerchantsshow">
			<el-table :data="merchantslist" v-loading="merchantsloading" class="table"
				:header-row-style="{'height':'48px'}">
				<el-table-column prop="MallName" label="商家"></el-table-column>
				<el-table-column prop="IsAuth" label="小程序授权状态">
					<template slot-scope="scope">
						{{scope.row.IsAuth?'已授权':'未授权'}}
					</template>
				</el-table-column>
				<el-table-column prop="InviteTime" label="推荐时间"></el-table-column>
			</el-table>
			<div style="margin-top:10px;text-align:right" v-if="total2">
				<el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
					:current-page="currentPage2" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage2"
					layout="total, sizes, prev, pager, next, jumper" :total="total2">
				</el-pagination>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="channelshow" title="渠道商家" @close="closechannelshow">
			<el-table :data="channellist" v-loading="channelloading" class="table"
				:header-row-style="{'height':'48px'}">
				<el-table-column prop="MallName" label="商家"></el-table-column>
				<el-table-column prop="IsAuth" label="小程序授权状态">
					<template slot-scope="scope">
						{{scope.row.IsAuth?'已授权':'未授权'}}
					</template>
				</el-table-column>
				<el-table-column prop="InviteTime" label="渠道绑定时间"></el-table-column>
			</el-table>
			<div style="margin-top:10px;text-align:right" v-if="total3">
				<el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
					:current-page="currentPage3" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage3"
					layout="total, sizes, prev, pager, next, jumper" :total="total3">
				</el-pagination>
			</div>
		</el-dialog>

		<el-dialog v-if="postershow" :visible.sync="postershow" v-loading="posterloading" @close = "closeposter">
			<div slot="title" class="flexRow" style="justify-content: center;font-size:18px">
				下载邀请海报
			</div>
			<div>
				<el-carousel :interval="0" type="card" height='650px' @change="changecarouselindex">
					<el-carousel-item v-for="(v,i) in posterlinklist" :key="i">
						<div style="margin:0px auto;width:300px">
							<img :src="v" :id="'img'+i" style="width:100%" alt="">
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="flexRow" style="justify-content: center;font-size:18px">
				<el-button type="text" @click="download">下载海报</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import vueQr from 'vue-qr'
	import createImage from '@/utils/createPosterImage';
	import {
		recommrndawrdmallrecommrndawrd,
		recommrndawrdrecommrndmallpagelist,
		recommrndawrdchannelbusinessmallpagelist,
		mallinviteposterlist
	} from "@/api/sv1.0.0"
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				pageloading: false,
				poster: '',
				posterImage: '',
				toploading: false,
				Inputlink: '',
				Inputlink2: '',
				topmsg: {},
				tablelist: [],
				tableloading: false,
				currentPage: 1,
				sizepage: 20,
				total: null,

				merchantsshow: false,
				merchantslist: [],
				merchantsloading: false,
				currentPage2: 1,
				sizepage2: 10,
				total2: null,

				channelshow: false,
				channellist: [],
				channelloading: false,
				currentPage3: 1,
				sizepage3: 10,
				total3: null,

				postershow: false,
				defaultposterlinklist:[],
				posterlinklist: [],
				posterindex: 0,
				url: '',
				posterloading: false,
			}
		},
		created() {

			this.setlinkinput()
			this.getposterlist()
			this.gettablelist()

		},
		methods: {
			closeposter(){
				this.posterindex = 0
				this.posterlinklist = JSON.parse(JSON.stringify(this.defaultposterlinklist))
			},
			btndownloadposter(){
				if(this.posterlinklist.length>1){
					this.postershow = true
				}else{
					this.downloadposter(-1)
				}
			},
			qrcallback(url, bg, type, i) {
				
				if (this.url) return;
				
				this.url = url;
				createImage.createPosterImg({
					bg: bg,
					qrCode: url
				}).then(res => {
					this.posterImage = res;
				})
			},
			setlinkinput() {
				// let head = ''
				// if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
				// 	head = 'https://pc.qidian.shop'
				// } else {
				// 	head = 'http://pc.dkytest.cn'
				// }
				this.Inputlink = location.origin + '/index.html#/freeOpen?mallId=' + window.localStorage.getItem('PCMallId')
			},
			getBase64Image(img) {
				var canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;
				var ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0, img.width, img.height);
				var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
				var dataURL = canvas.toDataURL("image/" + ext);
				return dataURL;
			},
			downloadposter(num) {
				var image = new Image()
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height

					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png', 1)

					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')

					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = '邀请海报'
					// 将生成的URL设置为a.href属性
					a.href = url
					// 触发a的单击事件
					a.dispatchEvent(event)
				}
				this.$nextTick(()=>{
				image.src = document.getElementById('img' + num).src
				})
			},
			async getnelist(list) {
				return new Promise((res, rej) => {
					let reslist = [];
					list.map((v)=>{
					  reslist.push(createImage.createPosterImg({
					    bg: v.InvitePosterImgUrlComplete,
					    qrCode: this.url
					  }))
					})
					
					Promise.all(reslist).then((suc) => {
						res(suc)
					}).catch(() => {
						rej()
					})
				})
			},
			async getposterlist() {
				this.pageloading = true
				try {
					let res = await mallinviteposterlist()
					if (res.IsSuccess) {
						this.posterlinklist = res.Result
            this.posterindex = 0
						this.poster = res.Result[0].InvitePosterImgUrlComplete
						if (this.posterlinklist && this.posterlinklist.length) {
							this.getnelist(this.posterlinklist).then((res) => {
								// this.postershow = true
								this.defaultposterlinklist = res
								this.posterlinklist = JSON.parse(JSON.stringify(res))
								this.posterImage = res[0]
							})
						}
					}
				} finally {
					this.pageloading = false
				}
			},
			changecarouselindex(index) {
				this.posterindex = index
			},
			download() {
				this.downloadposter(this.posterindex)
			},
			copysuc() {
				this.$message.success('复制成功')
			},
			copyerr() {
				this.$message.error('复制失败')
			},
			withdrawal() {
				this.$router.push({
					path: '/assets/myrecommended/withdrawal'
				})
			},
			closemerchantsshow() {
				this.currentPage2 = 1
				this.sizepage2 = 10
			},
			lookmerchants() {
				this.merchantsshow = true
				this.getmerchantslist()
			},
			lookchannel() {
				this.channelshow = true
				this.getchannellist()
			},
			closechannelshow() {
				this.currentPage3 = 1
				this.sizepage3 = 10
			},
			handleSizeChange3(e) {
				this.currentPage3 = 1
				this.sizepage3 = e
				this.getchannellist()
			},
			handleCurrentChange3(e) {
				this.currentPage3 = e
				this.getchannellist()
			},
			async getchannellist() {
				this.channelloading = true
				try {
					let data = {
						Skip: (this.currentPage3 - 1) * this.sizepage3,
						Take: this.sizepage3
					}
					let result = await recommrndawrdchannelbusinessmallpagelist(data)
					if (result.IsSuccess) {
						this.channellist = result.Result.Results
						this.total3 = result.Result.Total
					}
				} finally {
					this.channelloading = false
				}
			},
			handleSizeChange2(e) {
				this.currentPage2 = 1
				this.sizepage2 = e
				this.getmerchantslist()
			},
			handleCurrentChange2(e) {
				this.currentPage2 = e
				this.getmerchantslist()
			},
			async getmerchantslist() {
				this.merchantsloading = true
				try {
					let data = {
						Skip: (this.currentPage2 - 1) * this.sizepage2,
						Take: this.sizepage2
					}
					let result = await recommrndawrdrecommrndmallpagelist(data)
					if (result.IsSuccess) {
						this.merchantslist = result.Result.Results
						this.total2 = result.Result.Total
					}
				} finally {
					this.merchantsloading = false
				}
			},
			handleSizeChange(e) {
				this.currentPage = 1
				this.sizepage = e
				this.gettablelist()
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.gettablelist()
			},
			async gettablelist() {
				this.toploading = true
				this.tableloading = true
				try {
					let data = {
						Skip: (this.currentPage - 1) * this.sizepage,
						Take: this.sizepage
					}
					let result = await recommrndawrdmallrecommrndawrd(data)
					if (result.IsSuccess) {
						// console.log(result)
						this.topmsg = result.Result
						this.tablelist = result.Result.Results
						this.total = result.Result.Total
					}
				} finally {
					this.toploading = false
					this.tableloading = false
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;

	}

	.flexbetween {
		justify-content: space-between;
	}

	.flexRow2 {
		display: flex;
		flex-direction: row;
	}

	.flexCol {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.flexCol2 {
		display: flex;
		flex-direction: column;
	}

	.numfont {
		font-size: 18px;
		font-weight: bold
	}

	.textfont {
		font-size: 14px;
		color: rgb(180, 180, 180);
		margin-top: 10px
	}

	.titfont {
		font-size: 18px;
		font-weight: bold;
	}

	.grayfont {
		color: #606266;
		font-size: 12px;
	}

	.heightfull {
		height: 100%;
		/* border:1px solid black; */
		flex: 1;
	}

	.qrlocation {
		/* border:1px solid black; */
		position: relative;
		top: -51px;
		left: 8px;
	}
</style>
<style>
	.table .el-table__body td,
	.el-table__body th {
		padding: 0px !important;
		height: 60px;
	}
</style>

<style lang="less" scoped>

</style>
